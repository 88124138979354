import React, { useState } from "react";
import "./verticaltabs.scss";
import { useNavigate, Link } from "react-router-dom";
import { CommentIcon, TablerIcon, UploadFileIcon } from "../../assets/SVG/svg";

// redux states
import { useDispatch, useSelector } from "react-redux";
import { disableAllChatMode } from "../../ReduxSlices/ChatModeSlice";
import { useTracking } from "react-tracking";
import { useTranslation } from "react-i18next";
import { setKillRequest } from "../../ReduxSlices/RequestKillerSlice";

const VerticalTabs = () => {
  const { trackEvent } = useTracking({ component: 'VerticalTabs' });
  const {t} = useTranslation()


  const navigate = useNavigate();
  const [stateKey, setStateKey] = useState(0); // Add a state variable

  const dispatch = useDispatch()
  const requestActive = useSelector((state)=>state.requestActive)
  return (
    <>
      <div className="TabBox">
       
        <Link
          to="/"
          className="nav-btn tooltip advise-btn "
          state={{ myState: stateKey + 1 }}
          onClick={()=>{
            dispatch(disableAllChatMode())
            // console.log(`Vertical Tab:
            // requestActive=${requestActive}          
            // `)
            if (requestActive === true){
              dispatch(setKillRequest("chatcall"))
              }
              else {
                dispatch(setKillRequest("none"))
              }
            localStorage.setItem("mode", null)
            window.dispatchEvent(new Event('storage'));
            trackEvent({event_type:"click", event_name:"Module Switched", event_data: {action: "module was changed", module_selected: "Advise"}})
          }}
        >
          <div className="tooltipTextWrapper">
            <span class="tooltiptext">{t("advise")}</span>
          </div>
          <CommentIcon />
        </Link>
        <a
          className="nav-btn tooltip upload"
          onClick={() => {
            dispatch(disableAllChatMode());
            localStorage.setItem("mode", null)
            window.dispatchEvent(new Event('storage'));
            if(!window.location.href.includes("invoice"))
            trackEvent({event_type:"click", event_name:"Module Switched", event_data: {action: "module was changed", module_selected: "Process"}})
            navigate("/invoice")}}
        >
          <div className="tooltipTextWrapper">
            <span class="tooltiptext">{t("process")}</span>
          </div>
          <UploadFileIcon />
        </a>
        <a
          className="nav-btn tooltip tabler"
          onClick={() => {
            dispatch(disableAllChatMode()); 
            localStorage.setItem("mode", null)
            window.dispatchEvent(new Event('storage'));
            if(!window.location.href.includes("analyse"))
            trackEvent({event_type:"click", event_name:"Module Switched", event_data: {action: "module was changed", module_selected: "Analyse"}})
            navigate("/analyse")}}
        >
          <div className="tooltipTextWrapper">
            <span class="tooltiptext">{t("analyse")}</span>
          </div>
          <TablerIcon />
        </a>
      </div>
    </>
  );
};
export default VerticalTabs;
