export const payment_plans = [

    // trial plan
    {
        "id": "c7a41971-c177-4069-b74e-0a53d9a88b78",
        "created_at": "2024-03-02T12:26:22.064383Z",
        "updated_at": "2024-03-02T12:26:22.068726Z",
        "name": "Trial",
        "expiry_duration": "1000 00:00:00",
        "credits": 30,
        "price": 0.0,
        "signoff_credits": 0,
        "description": "",
        "metadata": {
            "button_text": "Continue Simpla Trial",
            "custom_plan": "false",
            "price_text": "Free",
            "price_note": "for 14 days",
            "plan_features": [
                "30 uses of Simpla only",
                "Try 3 powerful financial tools: Advise, Process and Analyse",
                "One company profile"
            ],
            "plan_description": "Discover new opportunities using AI in financial advisory."
        },
        "parent_id": null
    },

    // questioner
    {
        "id": "be9fb0e5-4d18-4a54-a1c2-dd39ddcf8c7c",
        "created_at": "2024-03-02T12:26:45.176405Z",
        "updated_at": "2024-03-02T12:26:45.179668Z",
        "name": "Questioner",
        "expiry_duration": "1000 00:00:00",
        "credits": 20,
        "price": 0.0,
        "signoff_credits": 0,
        "description": "",
        "metadata": {},
        "parent_id": null
    },

    // Base plan
    {
        "id": "baseplanid",
        "created_at": "2024-05-10T13:13:30.898913Z",
        "updated_at": "2024-05-10T13:13:31.073388Z",
        "name": "Base",
        "expiry_duration": "00:00:00",
        "credits": 50,
        "price": 18.99,
        "signoff_credits": 1,
        "description": "Our Base Plan covers all that you need from our advisory platform for an affordable price.",
        "metadata": {
            "end_note": null,
            "button_text": "Select Simpla Base",
            "custom_plan": "false",
            "plan_features": [
                "50 uses of Simpla per month",
                "Use 3 powerful financial tools: Advise, Process and Analyse",
                "Multiple company profile"
            ],
            "plan_description": "Our Base Plan covers all that you need from our advisory platform for an affordable price."
        },
        "parent_id": null
    },

    // base-monthly
    {
        "id": "basemonthlyid",
        "created_at": "2024-05-10T13:13:30.898913Z",
        "updated_at": "2024-05-10T13:13:31.073388Z",
        "name": "Base-Monthly",
        "expiry_duration": "00:00:00",
        "credits": 50,
        "price": 18.99,
        "signoff_credits": 1,
        "description": "Our Base Plan covers all that you need from our advisory platform for an affordable price.",
        "metadata": {
            "end_note": null,
            "button_text": "Select Simpla Base",
            "custom_plan": "false",
            "plan_features": [
                "50 uses of Simpla per month",
                "Use 3 powerful financial tools: Advise, Process and Analyse",
                "Multiple company profile"
            ],
            "plan_description": "Our Base Plan covers all that you need from our advisory platform for an affordable price."
        },
        "parent_id": "baseplanid"
    },

    // base-annual
    {
        "id": "baseannualid",
        "created_at": "2024-05-10T13:13:30.898913Z",
        "updated_at": "2024-05-10T13:13:31.073388Z",
        "name": "Base-Annual",
        "expiry_duration": "00:00:00",
        "credits": 50,
        "price": 193.70,
        "signoff_credits": 1,
        "description": "Our Base Plan covers all that you need from our advisory platform for an affordable price.",
        "metadata": {
            "end_note": null,
            "button_text": "Select Simpla Base",
            "custom_plan": "false",
            "plan_features": [
                "50 uses of Simpla per month",
                "Use 3 powerful financial tools: Advise, Process and Analyse",
                "Multiple company profile"
            ],
            "plan_description": "Our Base Plan covers all that you need from our advisory platform for an affordable price."
        },
        "parent_id": "baseplanid"
    },
    

    // starter
    {
        "id": "ce57eeda-7172-420d-a975-d26bcf209c55",
        "created_at": "2024-05-10T13:13:30.898913Z",
        "updated_at": "2024-05-10T13:13:31.073388Z",
        "name": "Starter",
        "expiry_duration": "00:00:00",
        "credits": 100,
        "price": 34.99,
        "signoff_credits": 1,
        "description": "Implement game-changing financial tech into your workflow.",
        "metadata": {
            "end_note": "*Only IFRS, KSA & UAE tax are currently supported, more coming soon.",
            "button_text": "Select Simpla Starter",
            "custom_plan": "false",
            "plan_features": [
                "100 uses of Simpla per month",
                "One human advisor validation per month*",
                "Multiple company profile"
            ],
            "plan_description": "Implement game-changing financial tech into your workflow."
        },
        "parent_id": null
    },

    // starter-monthly
    {
        "id": "e3446ae6-5b2a-4212-abab-a5d16f32c82a",
        "created_at": "2024-05-10T13:16:44.019263Z",
        "updated_at": "2024-05-10T13:16:44.190281Z",
        "name": "Starter-Monthly",
        "expiry_duration": "30 00:00:00",
        "credits": 100,
        "price": 34.99,
        "signoff_credits": 1,
        "description": "",
        "metadata": {
            "end_note": "*Only IFRS, KSA & UAE tax are currently supported, more coming soon.",
            "button_text": "Continue Simpla Starter",
            "custom_plan": "false",
            "plan_features": [
                "100 uses of Simpla only per month",
                "Use 3 powerful financial tools: Advise, Process and Analyse",
                "Multiple company profile"
            ],
            "plan_description": "Implement game-changing financial tech into your workflow."
        },
        "parent_id": "ce57eeda-7172-420d-a975-d26bcf209c55"
    },

    //starter-annual
    {
        "id": "f43644bd-7fc8-4723-b629-66f469a73938",
        "created_at": "2024-05-10T13:19:29.350689Z",
        "updated_at": "2024-05-10T13:19:29.522882Z",
        "name": "Starter-Annual",
        "expiry_duration": "30 00:00:00",
        "credits": 100,
        "price": 359.88,
        "signoff_credits": 1,
        "description": "",
        "metadata": {
            "end_note": "*Only IFRS, KSA & UAE tax are currently supported, more coming soon.",
            "button_text": "Continue Simpla Starter",
            "custom_plan": "false",
            "plan_features": [
                "100 uses of Simpla only per month",
                "Use 3 powerful financial tools: Advise, Process and Analyse",
                "Multiple company profile"
            ],
            "plan_description": "Implement game-changing financial tech into your workflow."
        },
        "parent_id": "ce57eeda-7172-420d-a975-d26bcf209c55"
    },

    // Growth plan
    {
        "id": "growthplanid",
        "created_at": "2024-05-10T13:13:30.898913Z",
        "updated_at": "2024-05-10T13:13:31.073388Z",
        "name": "Growth",
        "expiry_duration": "00:00:00",
        "credits": 1000,
        "price": 59.99,
        "signoff_credits": 2,
        "description": "Get more out of our AI-powered platform with features such as Sign-off.",
        "metadata": {
            "end_note": "*Only IFRS, KSA & UAE tax are currently supported, more coming soon.",            
            "button_text": "Select Simpla Growth",
            "custom_plan": "false",
            "plan_features": [
                "Unlimited uses of Simpla",
                "Two human advisor validations per month*",
                "One profile for one user"
            ],
            "plan_description": "Get more out of our AI-powered platform with features such as Sign-off."
        },
        "parent_id": null
    },

    // growth-monthly
    {
        "id": "growthmonthlyid",
        "created_at": "2024-05-10T13:13:30.898913Z",
        "updated_at": "2024-05-10T13:13:31.073388Z",
        "name": "Growth-Monthly",
        "expiry_duration": "00:00:00",
        "credits": 1000,
        "price": 59.99,
        "signoff_credits": 2,
        "description": "Get more out of our AI-powered platform with features such as Sign-off.",
        "metadata": {
            "end_note": "*Only IFRS, KSA & UAE tax are currently supported, more coming soon.",            
            "button_text": "Select Simpla Growth",
            "custom_plan": "false",
            "plan_features": [
                "Unlimited uses of Simpla",
                "Two human advisor validations per month*",
                "One profile for one user"
            ],
            "plan_description": "Get more out of our AI-powered platform with features such as Sign-off."
        },
        "parent_id": "growthplanid"
    },


    // growth-annual
    {
        "id": "growthannualid",
        "created_at": "2024-05-10T13:13:30.898913Z",
        "updated_at": "2024-05-10T13:13:31.073388Z",
        "name": "Growth-Annual",
        "expiry_duration": "00:00:00",
        "credits": 0,
        "price": 611.90,
        "signoff_credits": 2,
        "description": "Get more out of our AI-powered platform with features such as Sign-off.",
        "metadata": {
            "end_note": "*Only IFRS, KSA & UAE tax are currently supported, more coming soon.",            
            "button_text": "Select Simpla Growth",
            "custom_plan": "false",
            "plan_features": [
                "Unlimited uses of Simpla",
                "Two human advisor validations per month*",
                "One profile for one user"
            ],
            "plan_description": "Get more out of our AI-powered platform with features such as Sign-off."
        },
        "parent_id": "growthplanid"
    },

    // enterprise
    {
        "id": "ecef4dad-6c48-4bcd-84c0-3da85574c3d5",
        "created_at": "2024-05-10T13:21:51.689311Z",
        "updated_at": "2024-05-10T13:21:51.861986Z",
        "name": "Enterprise",
        "expiry_duration": "30 00:00:00",
        "credits": 0,
        "price": 0.0,
        "signoff_credits": 0,
        "description": "Game-changing financial technology - ideal for SMEs, accounting and advisory firms.",
        "metadata": {
            "button_text": "Discover Simpla Enterprise",
            "custom_plan": true,
            "plan_features": [
                "Unlimited uses of Simpla",
                "Bespoke app tailored to your company's needs",
                "Unlimited users and profiles for your team"
            ],
            "plan_description": "Game-changing financial technology - ideal for SMEs, accounting and advisory firms."
        },
        "parent_id": null
    }
]
