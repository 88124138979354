import React, { useEffect, useRef, useState } from "react";
import {
  HistoryIcon,
  HelpIcon,
  PlusIconDark,
  LogoIcon,
} from "../../assets/SVG/svg";
import Collapse from "react-bootstrap/Collapse";
import styles from "./Sidebar.module.scss";
import ButtonRounded from "../common/Button/ButtonRounded";
import HistoryModal from "../history/history";
import VerticalTabs from "../VerticalTabs";
import VerticalTabsHelp from "../VerticalTabsHelp";

import Drawer from '@mui/material/Drawer';
import { Button, Box, Typography } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';

import "./Sidebar.css"

// redux
import { useSelector, useDispatch } from "react-redux";
import { setDisplaySidebar } from "../../ReduxSlices/SidebarSlice";
import { disableAllChatMode } from "../../ReduxSlices/ChatModeSlice";
import { useTranslation } from "react-i18next";
import { useTour } from "@reactour/tour";
import { getTutorialStepsById } from "../../ReduxSlices/InteractiveTutorialSlice";
import { setInteractiveTutorialInfo } from "../../ReduxSlices/activateInteractiveTutorial";
import { useNavigate } from "react-router-dom";
import { finishStep } from "../../ReduxSlices/StepsSlice";
import { saveStepProgress } from "../../App";
import { toggleTourButton, toggleTourButtonOff } from "../../ReduxSlices/tourButtonSlice";

const Sidebar = (props) => {

  const { t } = useTranslation()
  const [history, setHistory] = useState(false);
  const [open, setOpen] = useState(false);
  const [HelpOpen, setHelpOpen] = useState(false);
  const { setIsOpen ,setSteps,setCurrentStep,currentStep,isOpen,steps} = useTour();
  const tutorialSteps = useSelector((state) => state.interactiveTutorialSteps)
  const navigate=useNavigate();
  const {open_help}=useSelector((state)=>state.tourButtonSlice);

  const dispatch = useDispatch()
  const displayBar = useSelector((state) => state.displaySidebar.value);
  var tutorial_steps= localStorage.getItem('tutorial_steps');
  if(tutorial_steps){
    tutorial_steps=JSON.parse(tutorial_steps)
  }

  // const sidebarRef = useRef(null);


  // const handleOutsideClick = (event) => {
  //   if ( sidebarRef.current && !sidebarRef.current.contains(event.target)) {
  //     //dispatch(setDisplaySidebar('none'))
  //   }
  // };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 600 && displayBar === 'none') {
        setHistory(false)
      }
    };

    window.addEventListener('resize', handleResize);

  }, [displayBar]);


  useEffect(()=>{
    if(open === true ){
     
      var tutorial_step= tutorial_steps.find((t)=>t.id ==='new_chat');
      console.log(tutorial_step);
      if(tutorial_step?.completed === false && localStorage.getItem('tutorial_active')==='true' ){
        setSteps(getTutorialStepsById('new_chat'));
        setCurrentStep(0);
        setIsOpen(true);
        // dispatch(setInteractiveTutorialInfo({id:'new_chat',activate:true}))
      }
     
    }
  },[open])
  useEffect(() => {
    let timeoutRef = null; 
  
    if (isOpen && steps[0]?.selector === '.advise-btn') {
      console.log('advise-btn triggered');
  
      // Clear any existing timeout before setting a new one
      if (timeoutRef) {
        clearTimeout(timeoutRef);
      }
  
      // Set timeout for incrementing the step
      timeoutRef = setTimeout(() => {
        if (isOpen && steps[0]?.selector === '.advise-btn') {
          setCurrentStep((prevStep) => prevStep + 1); // Use functional update
        }
      }, 5000);
  
      // Additional logic when `currentStep === 2`
      if (currentStep === 2 && steps[0]?.selector === '.advise-btn') {
        // Clear any existing timeout before setting a new one
        if (timeoutRef) {
          clearTimeout(timeoutRef);
        }
  
        timeoutRef = setTimeout(() => {
          if (isOpen && steps[0]?.selector === '.advise-btn') {
            saveStepProgress(7);
            setSteps([]);
            setCurrentStep(0);
            setIsOpen(false);
          }
        }, 5000);
  
        // Uncomment the dispatch when needed
        // dispatch(finishStep({ index: 8 }));
      }
    }
  
    // Cleanup function to clear timeout when dependencies change or component unmounts
    return () => {
      if (timeoutRef) {
        clearTimeout(timeoutRef);
        timeoutRef = null;
      }
    };
  }, [currentStep, steps, isOpen]);
  

  useEffect(()=>{
    if(HelpOpen === true){
  
      var tutorial_step =tutorial_steps.find((t)=>t.id ==='help-tutorial');
      if(tutorial_step?.completed === false  && localStorage.getItem('tutorial_active')==='true'){
        setTimeout(()=>{
          setSteps(getTutorialStepsById('help-tutorial'));
          setCurrentStep(0);
          setIsOpen(true);
        },500)
       
       
        // dispatch(setInteractiveTutorialInfo({id:'help-tutorial',activate:true}))
      }

    }

  },[HelpOpen])

  //.advise-btn

  useEffect(() => {
    const maskElement = document.querySelector('.reactour__popover');
  
    if (isOpen &&   steps[0]?.selector === '.advise-btn'&& maskElement) {
      maskElement.style.marginTop = '5px'; // Apply the style
      maskElement.style.marginLeft = '42px';
    }
 
  
    return () => {
      if (maskElement) {
        maskElement.style.marginTop = ''; // Reset the style to its default
        maskElement.style.marginTop = '';
      }
    };
  }, [steps, isOpen,currentStep]);

  useEffect(() => {
    const maskElement = document.querySelector('.reactour__popover');
  
    if (isOpen &&   steps[0]?.selector === '.AiChats'&& maskElement) {
      maskElement.style.marginTop = '42px'; // Apply the style
    }
 
  
    return () => {
      if (maskElement) {
        maskElement.style.marginTop = ''; // Reset the style to its default
      }
    };
  }, [steps, isOpen,currentStep]);

  useEffect(() => {
    const maskElement = document.querySelector('.reactour__popover');
  
    if (isOpen &&   steps[0]?.selector === '.mark-favourite-icon'&& maskElement) {
      maskElement.style.marginTop = '42px'; // Apply the style
    }
 
  
    return () => {
      if (maskElement) {
        maskElement.style.marginTop = ''; // Reset the style to its default
      }
    };
  }, [steps, isOpen,currentStep]);

  useEffect(() => {
    const maskElement = document.querySelector('.reactour__popover');
  
    if (isOpen &&   steps[0]?.selector === '.vediobtn' && maskElement) {
      maskElement.style.marginLeft = '42px'; // Apply the style
    }
 
  
    return () => {
      if (maskElement) {
        maskElement.style.marginTop = ''; // Reset the style to its default
      }
    };
  }, [steps, isOpen,currentStep]);

  //.mark-favourite-icon

 useEffect(()=>{
  console.log(open_help)
  if(open_help ){
    setHelpOpen(true);
    dispatch(toggleTourButtonOff());
    setTimeout(()=>{
      setSteps(getTutorialStepsById('tour_skipped'));
      setCurrentStep(0);
      setIsOpen(true);
   
      setTimeout(()=>{
        if(isOpen===true){
          setIsOpen(false);
         
        }
       
    },5000)
    },1000)
   

   
  }
  else{
    return;
  }
 },[open_help])

 useEffect(() => {
  let timeoutRef = null; // Local variable to store timeout ID

  if (isOpen && steps[0]?.selector === '.AiChats' && currentStep === 0) {
    console.log('.AiChats selector triggered');

    // Clear any existing timeout before setting a new one
    if (timeoutRef) {
      clearTimeout(timeoutRef);
    }

    timeoutRef = setTimeout(() => {
      // Ensure conditions are still valid before updating state
      if (isOpen && steps[0]?.selector === '.AiChats') {
        setCurrentStep((prevStep) => prevStep + 1); // Use functional update
      }
    }, 5000);
  }

  // Cleanup function to clear timeout when dependencies change or component unmounts
  return () => {
    if (timeoutRef) {
      clearTimeout(timeoutRef);
      timeoutRef = null;
    }
  };
}, [currentStep, steps]);

  //   useEffect(() => {
  //   if (displayBar === 'block') {
  //     window.addEventListener('click', handleOutsideClick);
  //   }

  //   return () => {
  //     window.removeEventListener('click', handleOutsideClick);
  //   };
  // }, [displayBar]);
  const borderRadiusStyle = localStorage.getItem('i18nextLng')?.toString() === "ar" ? "0px 0px 0px 90px" : "0px 0px 90px 0px";


  useEffect(() => {
    let timeoutRef1 = null; // Timeout for currentStep <= 3
    let timeoutRef2 = null; // Timeout for currentStep === 3
  
    if (isOpen && steps[0]?.selector === '.vediobtn' && currentStep <= 3) {
      console.log('videobtn triggered current page !==3');
      
      // Clear previous timeout if any
      if (timeoutRef1) {
        clearTimeout(timeoutRef1);
      }
  
      timeoutRef1 = setTimeout(() => {
        if (isOpen && steps[0]?.selector === '.vediobtn') {
          console.log('isopen condition triggered');
          setCurrentStep((prevStep) => prevStep + 1); // Functional state update
        }
      },5000);
    }
  
    if (isOpen && steps[0]?.selector === '.vediobtn' && currentStep === 3) {
      
      setIsOpen(false);
      setCurrentStep(0);
      setSteps([]);
      
      const tutorial_step = tutorial_steps.find((t) => t.id === 'new_chat');
      console.log(tutorial_step, 'new-chat');
  
      if (tutorial_step?.completed === false && localStorage.getItem('tutorial_active') === 'true') {
        setCurrentStep((prevStep) => prevStep + 1);
      } else {
        if (isOpen) {
          setIsOpen(false);
          setSteps([]);
          setCurrentStep(0);
        }
      }
      saveStepProgress(6);
  
      // Timeout to clear after step progress
      if (timeoutRef2) {
        clearTimeout(timeoutRef2);
      }
  
      timeoutRef2 = setTimeout(() => {
        // Additional cleanup or dispatch logic if necessary
        console.log('Additional post-progress logic here');
      }, 5000);
  
      // Uncomment if you need to dispatch after finishing
      // dispatch(finishStep({ index: 7 }));
    }
  
    // Cleanup function to clear all timeouts
    return () => {
      if (timeoutRef1) {
        clearTimeout(timeoutRef1);
      }
      if (timeoutRef2) {
        clearTimeout(timeoutRef2);
      }
    };
  }, [currentStep, steps, isOpen]);


  
  

  useEffect(()=>{
    if(history===true){

      var tutorial_step=tutorial_steps.find((t)=>t.id ==='history-chats')
      if (tutorial_step?.completed === false &&  props?.chatroomsdata &&  localStorage.getItem('tutorial_active')==='true' ){
        setSteps(getTutorialStepsById('history-chats'));
        setCurrentStep(0);
        setIsOpen(true);

      }
      
    }
  },[history])

  const closeHistory=()=>{
    setHistory(false);
  }
  const closeHelp=()=>{
    setOpen(false);
  }

  

  return (

    <>
     
      <div>


{/* 
<div style={{margin:'50px 0px 0px 10px'}}>
    <MenuIcon fontSize="large" onClick={handleDisplay}/>
</div> */}

<div className="desktop-sidebar">
<div
  className={`${styles.sidenavWrapper} ${props.styleClass} ${history ? styles.noBorderRadius : ''}`}
  style={{ borderRadius: borderRadiusStyle }}
>
    <div className={`${styles.sidebarMenuWrapper}  ${history ? 'sideMenuWrapperShadow' : ''}`}>
      <div className={styles.topSection}>
        <div className={styles.ItemList}>
          <div
            className={`${styles.collapseWraper} ${open ? "collapseWraper" : null
              } `}
          >
            <ButtonRounded
              buttonIcon={<PlusIconDark />}
              styleclass="btn-rounded-lg add-btn add-btn-desktop tooltip btn-sidenav-active"
              btnBorder="border-color"
              onClick={() => { setHelpOpen(false); setOpen(!open) }}
              tooltipText={`${t("new_chat")}`}
            />
            <Collapse in={open}>
              <div id="example-collapse-text">
                <VerticalTabs />
              </div>
            </Collapse>
          </div>
          <hr />
          <ButtonRounded
          
            buttonIcon={<HistoryIcon />}
            styleclass={`history-button btn-rounded-lg bg-color-sidenav border-0 btn-history-desktop btn-history  tooltip ${history ? "history-active" : null
              }`}
            btnBorder="none"
            tooltipText={`${t("history")}`}
            onClick={() => {
              setHistory(true);

            
              props?.refetch();
            }}
          />
          <div style={{ paddingBottom: "50px" }}>
            <div
              className={`${styles.collapseWraper} ${setOpen ? "collapseWraper" : null
                } `}
            >
              <ButtonRounded
                buttonIcon={<HelpIcon />}
                styleclass={`help-button tooltip btn-rounded-lg bg-color-sidenav border-0 btn-help-desktop btn-help ${HelpOpen ? "btn-sidenav-active btn-help-active" : null
                  }`}
                btnBorder="none"
                onClick={() => { setOpen(false); setHelpOpen(!HelpOpen);
                 
                 }}
                tooltipText={`${t("help")}`}
              />
              <Collapse in={HelpOpen}>
                <div id="example-collapse-text">
                  <VerticalTabsHelp
                    productTour={props?.handleCloseOnboarding}
                    closeHistory={()=>setHistory(false)}
                    closeHelp={()=>setHelpOpen(false)}
                  />
                </div>
              </Collapse>
            </div>
          </div>

    

        </div>
      </div>
      {/* <div className={styles.logo}>
    <LogoIcon />
  </div> */}
    </div>
    {history && (
      <HistoryModal
        onClose={() => {
          setHistory(false);
          props?.filterSetter("All");
        }}
        refetch={props?.refetch}
        data={props?.chatroomsdata}
        loading={props?.loading}
        filterSetter={props?.filterSetter}
        filter={props?.filter}
        searchSetter={props?.searchSetter}
        search={props?.search}
      />
    )}
  </div>
</div>

{/* mobile side bar view */}

<div style={{ display: `${displayBar}`, }}>
  <div className={`${styles.sidenavWrapper} ${props.styleClass}`} >
    <div className={`${styles.sidebarMenuWrapper} ${history ? 'sideMenuWrapperShadow' : ''}`}>
      <div className={styles.topSection}>
        <div className={styles.ItemList} style={{paddingBottom:"50px"}}>
          <div
            className={`${styles.collapseWraper} ${open ? "collapseWraper" : null
              } `}
          >
            <ButtonRounded
              buttonIcon={<PlusIconDark />}
              styleclass="btn-rounded-lg add-btn add-btn-mobile tooltip btn-sidenav-active"
              btnBorder="border-color"
              onClick={() => { setHelpOpen(false); setOpen(!open) }}
              tooltipText={`${t("new_chat")}`}
            />
            <Collapse in={open}>
              <div id="example-collapse-text">
                <VerticalTabs  />
              </div>
            </Collapse>
          </div>
          <hr />
          <ButtonRounded
        buttonIcon={<HistoryIcon />}
        styleclass={`btn-rounded-lg bg-color-sidenav border-0 btn-history-mobile btn-history tooltip ${
          history ? "history-active" : null
        }`}
        btnBorder="none"
        tooltipText={`${t("history")}`}
        onClick={() => {
          setHistory(true);
          props?.refetch();
        }}
      />
          <div
            className={`${styles.collapseWraper} ${setOpen ? "collapseWraper" : null
              } `}
          >
            <ButtonRounded
              buttonIcon={<HelpIcon />}
              styleclass={` tooltip btn-rounded-lg bg-color-sidenav border-0 btn-help-mobile btn-help ${HelpOpen ? "btn-sidenav-active btn-help-active" : null
                }`}
              btnBorder="none"
              onClick={() => { setOpen(false); setHelpOpen(!HelpOpen) }}
              tooltipText={`${t("help")}`}
            />
            <Collapse in={HelpOpen}>
              <div id="example-collapse-text">
                <VerticalTabsHelp
                  productTour={props?.handleCloseOnboarding}
                  // closeHelp={setHelpOpen(false)}
                  closeHistory={()=>setHistory(false)}
                  closeHelp={()=>setHelpOpen(false)}

                />
              </div>
            </Collapse>
          </div>
        </div>
      </div>
      {/* <div className={styles.logo}>
    <LogoIcon />
  </div> */}
    </div>
    {history && (
      <HistoryModal
        onClose={() => {
          setHistory(false);
          props?.filterSetter("All");
        }}
        refetch={props?.refetch}
        data={props?.chatroomsdata}
        loading={props?.loading}
        filterSetter={props?.filterSetter}
        filter={props?.filter}
        searchSetter={props?.searchSetter}
        search={props?.search}
      />
    )}
  </div>
</div>



</div>
        
    </>

  





  );
};

export default Sidebar;
