import Spinner from 'react-bootstrap/Spinner';

function ButtonLoader() {
  return (
    <>
        <Spinner
          style={{width: '2rem', height: '2rem'}}
          as="span"
          animation="border"
          role="status"
          aria-hidden="true"
        />
    
    </>
  );
}

export default ButtonLoader;