import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    tutorial_steps: [
        { id: 'quick-setup-main-container', completed: false },
        { id: 'chatmode_selection', completed: false },
        { id: 'enter_query', completed: false },
        { id: 'view-sources', completed: false },
        { id: 'history-button', completed: false },
        { id: 'history-chats', completed: false },
        { id: 'help-tutorial', completed: false },
        { id: 'new_chat', completed: false },
        { id: 'suggested-prompts', completed: false },
        {id:'action-prompts-div',completed:false},
        {id:'action-prompt-email',completed:false},
        {id:'action-prompt-email-steps',completed:false},
        {id:'generate-email-prompt',completed:false},
        {id:'email-generate-response',completed:false}
    ]
};

const tutorialSlice = createSlice({
    name: 'tutorial',
    initialState,
    reducers: {
        finishStep: (state, action) => {
          const { index } = action.payload;
          // Directly mutate the specific step
          if (state.tutorial_steps[index]) {
            state.tutorial_steps[index].completed = true;
          }
        },
        resetSteps: (state) => {
          // Directly mutate all steps
          state.tutorial_steps.forEach((step) => {
            step.completed = false;
          });
        }
      }
});

export const { finishStep, resetSteps } = tutorialSlice.actions;
export default tutorialSlice.reducer;
