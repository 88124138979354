function localStorageValueTester(val){
  if (val !== null && val !== "null" && val !== undefined && val !== "undefined"){
      return true;
  }
  else{
      return false
  }
}

function filterEmptyStringProps(obj) {
  // Iterate over the object's properties
  for (const [key, value] of Object.entries(obj)) {
    // Check if the value is an empty string or contains only whitespace
    if (typeof value === "string" && value.trim() === "") {
      // Remove the property from the object
      delete obj[key];
    }
  }
  
  return obj;
}

// Format time as MM:SS
const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const secs = seconds % 60;
  return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
};

// string value tester wiil return true for 
function ValueTester(val){
  if (val && val !== null && val !== undefined && val !== "null" && val !== "undefined" && val?.toString()?.trim()?.length > 0){
    return true;
  }
  else return false
}

export {localStorageValueTester, filterEmptyStringProps, formatTime, ValueTester}

