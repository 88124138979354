import { ValueTester } from "./UtilityFunctions";

export const Environments = {
  "development": "http://127.0.0.1:8000/api/v1",
  "qa": "http://13.51.98.205:8000/api/v1",
  "dev": "https://sbx.simpla.ai:8000/api/v1",
  "prod": "https://app.simpla.ai:8000/api/v1"
}

const env_value = process.env.REACT_APP_PROJECT_MODE; 

var stripe_public_key_temp = process.env.REACT_APP_STRIPE_PUBLIC_KEY_TEST

var stripe_mode = process.env.REACT_APP_STRIPE_MODE?.toLowerCase()

if (stripe_mode === "test"){
  stripe_public_key_temp = process.env.REACT_APP_STRIPE_PUBLIC_KEY_TEST
}
else if (stripe_mode === "live"){
  stripe_public_key_temp = process.env.REACT_APP_STRIPE_PUBLIC_KEY_LIVE
}
else {
  switch (env_value) {
    case "development":
      stripe_public_key_temp = process.env.REACT_APP_STRIPE_PUBLIC_KEY_TEST;
      break;

    case "prod":
      stripe_public_key_temp = process.env.REACT_APP_STRIPE_PUBLIC_KEY_LIVE;
      break;

    default:
      stripe_public_key_temp = process.env.REACT_APP_STRIPE_PUBLIC_KEY_TEST;
      break;
  }
}

export const STRIPE_PULIC_KEY = stripe_public_key_temp
// console.log("stripe mode = ", stripe_mode, "key = ", stripe_public_key_temp)

let SERVER_URL = Environments[env_value] // default url based on 

let force_url = process.env.REACT_APP_FORCE_URL

if (ValueTester(force_url)){
  SERVER_URL = force_url
}

export const SERVICES = {
  apiBaseUrl: SERVER_URL,
};