import { createSlice } from "@reduxjs/toolkit";

const initialValue = {
    id: "chatmode_selection",
    activate: false,
    tutorial_complete: false,
    page: "/"
}
const interactiveTutorialInfoSlice = createSlice({
    name:'interactiveTutorialInfo',
    initialState: initialValue,
    reducers: {
        setInteractiveTutorialInfo: (state, action) => {
            return {...state, ...action.payload}
        }
    }
})

export const {setInteractiveTutorialInfo} = interactiveTutorialInfoSlice.actions;
export default interactiveTutorialInfoSlice.reducer