
const labelsEnglish = [
    "Trial",
    "Discover new opportunities using AI in financial advisory.",
    "30 uses of Simpla only",
    "Try 3 powerful financial tools: Advise, Process and Analyse",
    "One company profile",
    "Continue Simpla Trial",
    
    "Starter",
    "Implement game-changing financial tech into your workflow.",
    "50 uses of Simpla per month",
    "One human advisor validation per month*",
    "Multiple company profile",
    "*Only IFRS, KSA & UAE tax are currently supported, more coming soon.",
    "Select Simpla Starter",

    "Monthly",
    "Annual",
    "Yearly",
    
    "Enterprise",
    "Ideal for larger teams & accounting and advisory companies.",
    "Ask us",
    "Unlimited uses and profiles of Simpla AI",
    "Dedicated advisory support",
    "Custom tailored onboarding",
    "Discover Simpla Enterprise",

    // new additions:
    "Get more out of our AI-powered platform with features such as Sign-off.",
    "Our Base Plan covers all that you need from our advisory platform for an affordable price.",
    "Game-changing financial technology - ideal for SMEs, accounting and advisory firms.",
    "Unlimited uses of Simpla",
    "Bespoke app tailored to your company's needs",
    "Unlimited users and profiles for your team",
    "Two human advisor validations per month*",
    "One profile for one user",
    "100 uses of Simpla per month",
    "Use 3 powerful financial tools: Advise, Process and Analyse",
    "Select Simpla Base",
    "Select Simpla Growth",
    "for 14 days",
    "Free",
    "Base",
    "Growth",
  ];

  const labelsArabic = [
    "نسخة تجريبية",
    "اكتشف فرصًا جديدة باستخدام الذكاء الاصطناعي في الاستشارات المالية.",
    "30 استخدام في Simpla فقط",
    "قم بتجربة ثلاث أدوات مالية قوية: الاستشارة، المعالجة والتحليل",
    "ملف تعريف شركة واحدة",
    "متابعة استخدام نسخة Simpla التجريبية",

    "خطة ابتدائية",
    "قم بتطبيق التكنولوجيا المالية التي ستغير قواعد اللعبة في سير عملك.",
    "50 استخدام في Simpla شهريًا",
    "تحقق أخصّائي من الاستشارة واحد في الشهر*",
    "ملفات تعريف شركات متعددة",
    "* يتم حاليًا دعم المعايير الدولية في التقرير المالي (IFRS) والضرائب في السعودية والإمارات، ستتوفر المزيد من الخدمات قريبًا.",
    "اختر الخطة الابتدائية في Simpla",

    "شهري",
    "سنوي",
    "سنوي",
    
    "حساب المؤسّسات",
    "خطة مثالية لفريق كبير وشركات المحاسبة والاستشارات.",
    "اسألنا",
    "استخدامات وملفات تعريف غير محدودة لـ Simpla",
    "دعم استشاري مخصّص",
    "تأهيل مخصّص",
    "استكشف حساب المؤسّسات في Simpla",

    //new additions:

    "استفد أكثر من منصتنا المدعومة بالذكاء الاصطناعي مع ميزات مثل التحقق من الاعتماد.",
    "تغطي خطتنا الأساسية كل ما تحتاجه من منصتنا الاستشارية بسعر معقول.",
    "تكنولوجيا مالية تغيّر قواعد اللعبة - مثالية للشركات الصغيرة والمتوسطة وشركات المحاسبة والاستشارات.",
    "استخدامات غير محدودة لـ Simpla",
    "تطبيق مصمم خصيصًا لاحتياجات شركتك",
    "مستخدمون وملفات تعريف غير محدودة لفريقك",
    "تحققين من أخصائي بشري في الشهر*",
    "ملف تعريف واحد لمستخدم واحد",
    "100 استخدام لـ Simpla في الشهر",
    "استخدم 3 أدوات مالية قوية: الاستشارة، المعالجة، والتحليل",
    "اختر خطة Simpla الأساسية",
    "اختر خطة Simpla للنمو",
    "لمدة 14 يومًا",
    "مجاني",
    "الأساسية",
    "النمو"
  ];

  export function matchMaker(option, locale = `${localStorage.getItem("i18nextLng")}`) {
    if (locale === "ar") {
      let index = labelsEnglish.indexOf(option); // Use indexOf instead of findIndex
      // console.log(`
      //   op=${option}
      //   locale=${locale}
      //   index=${index}
      // `);
      if (index !== -1) { // Check if index is found
        return labelsArabic[index];
      } else {
        return option;
      }
    }
    return option;
  }