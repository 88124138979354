import { create } from "@mui/material/styles/createTransitions";
import { createSlice } from "@reduxjs/toolkit";
import { ValueTester } from "../utils/UtilityFunctions";
import { Box, Typography } from "@mui/material";
import CustomArrow from "../components/CustomArrow";



// const headerJoyride = (
//     <div className="joyride_header">
//       <label className="form-label"> {t("joyride_guide_me")} </label>
//       <Dropdown>
//         <Dropdown.Toggle id="dropdown-basic" className="bg-transparent border-0">
//         {t("joyride_select_feature")}
//         </Dropdown.Toggle>
  
     
//       </Dropdown>
//     </div>
//   );

const connector = (
    <Box
      sx={{
        position: "absolute",
        bottom: "-50px",
        left: "160px",
        minHeight: "35px",
        background: "#FF690F",
       border:'1px solid #FF690F'
      }}
    >
        <Box sx={{position: 'absolute',
    bottom: '-10px',
    minWidth: '11px',
    background: '#FF690F',
    borderRadius: '50px',
    minHeight: '11px',
    left: '-5px'}} > 

        </Box>
    </Box>
  );

  
const connector_12 = (
    <Box
      sx={{
        position: "absolute",
        bottom: "-60px",
        left: "160px",
        minHeight: "35px",
        background: "#FF690F",
       border:'1px solid #FF690F'
      }}
    >
        <Box sx={{position: 'absolute',
    bottom: '-10px',
    minWidth: '11px',
    background: '#FF690F',
    borderRadius: '50px',
    minHeight: '11px',
    left: '-5px'}} > 

        </Box>
    </Box>
  );

  const connector_10 = (
    <Box
      sx={{
        position: "absolute",
        transform:'rotate(270deg)',
        bottom: "17px",
        left: "427px",
        minHeight: "35px",
        background: "#FF690F",
       border:'1px solid #FF690F'
      }}
    >
        <Box sx={{position: 'absolute',
    bottom: '-10px',
    minWidth: '11px',
    background: '#FF690F',
    borderRadius: '50px',
    minHeight: '11px',
    left: '-5px'}} > 

        </Box>
    </Box>
  );

  const connector_15 = (
    <Box
      sx={{
        position: "absolute",
        transform:'rotate(270deg)',
        bottom: "6px",
        left: "407px",
        minHeight: "35px",
        background: "#FF690F",
       border:'1px solid #FF690F'
      }}
    >
        <Box sx={{position: 'absolute',
    bottom: '-10px',
    minWidth: '11px',
    background: '#FF690F',
    borderRadius: '50px',
    minHeight: '11px',
    left: '-5px'}} > 

        </Box>
    </Box>
  );


  const connector_11 = (
    <Box
      sx={{
        position: "absolute",
        transform:'rotate(89.5deg)',
        bottom: "30px",
        left: "-42px",
        minHeight: "38px",
        background: "#FF690F",
       border:'1px solid #FF690F'
      }}
    >
        <Box sx={{position: 'absolute',
    bottom: '-10px',
    minWidth: '11px',
    background: '#FF690F',
    borderRadius: '50px',
    minHeight: '11px',
    left: '-5px'}} > 

        </Box>
    </Box>
  );

  const connector_9 = (
    <Box
      sx={{
        position: "absolute",
        bottom: "-39px",
        left: "222px",
        minHeight: "42px",
        background: "#FF690F",
       border:'1px solid #FF690F'
      }}
    >
        <Box sx={{position: 'absolute',
    bottom: '-10px',
    minWidth: '11px',
    background: '#FF690F',
    borderRadius: '50px',
    minHeight: '11px',
    left: '-5px'}} > 

        </Box>
    </Box>
  );

  const connector_8 = (
    <Box
      sx={{
        position: "absolute",
        bottom: "-31px",
        left: "160px",
        minHeight: "30px",
        background: "#FF690F",
       border:'1px solid #FF690F'
      }}
    >
        <Box sx={{position: 'absolute',
    bottom: '-10px',
    minWidth: '11px',
    background: '#FF690F',
    borderRadius: '50px',
    minHeight: '11px',
    left: '-5px'}} > 

        </Box>
    </Box>
  );

  const connector_6 = (
    <Box
      sx={{
        position: "absolute",
        bottom: "-47px",
        left: "160px",
        minHeight: "35px",
        background: "#FF690F",
       border:'1px solid #FF690F'
      }}
    >
        <Box sx={{position: 'absolute',
    bottom: '-10px',
    minWidth: '11px',
    background: '#FF690F',
    borderRadius: '50px',
    minHeight: '11px',
    left: '-5px'}} > 

        </Box>
    </Box>
  );

  const connector_2 = (
<>
    <Box
        sx={{
          position: "absolute",
          top: "-108px", // Adjusted to align with new child position
          left: "154px", // Adjusted to align with new child position
          minHeight: "41px", // Adjusted from parent
          background: "#FF690F", // Adjusted from parent
          border: "1px solid #FF690F", // Adjusted from parent
        }}
      />
    <Box
      sx={{
        position: "absolute",
        bottom: "106px", // Adjusted from child
        minWidth: "11px", // Adjusted from child
        background: "#FF690F",
        borderRadius: "50px", // Adjusted from child
        minHeight: "11px", // Adjusted from child
        left: "149px", // Adjusted from child
      }}
    >
        
      
    </Box>
    </>
  );

  const connector_3 = (
    <Box
      sx={{
        position: "absolute",
        bottom: "-32px",
        left: "5px",
        minHeight: "32px",
        background: "#FF690F",
       border:'1px solid #FF690F'
      }}
    >
        <Box sx={{position: 'absolute',
    bottom: '-10px',
    minWidth: '11px',
    background: '#FF690F',
    borderRadius: '50px',
    minHeight: '11px',
    left: '-5px'}} > 

        </Box>
    </Box>
  );
  const connector_4 = (
    <Box
      sx={{
        position: "absolute",
       
        left: "34px",
        top:'-41px',
        minHeight: "42px",
        background: "#FF690F",
       border:'1px solid #FF690F'
      }}
    >
        <Box sx={{position: 'absolute',
   top:'-9px',
    minWidth: '11px',
    background: '#FF690F',
    borderRadius: '50px',
    minHeight: '11px',
    left: '-5px'}} > 

        </Box>
    </Box>
  );

  const connector_7 = (
    <>
        <Box
            sx={{
              position: "absolute",
              top: "-80px", // Adjusted to align with new child position
              left: "154px", // Adjusted to align with new child position
              minHeight: "42px", // Adjusted from parent
              background: "#FF690F", // Adjusted from parent
              border: "1px solid #FF690F", // Adjusted from parent
            }}
          />
        <Box
          sx={{
            position: "absolute",
            bottom: "77px", // Adjusted from child
            minWidth: "11px", // Adjusted from child
            background: "#FF690F",
            borderRadius: "50px", // Adjusted from child
            minHeight: "11px", // Adjusted from child
            left: "149px", // Adjusted from child
          }}
        >
            
          
        </Box>
        </>
      );
  

  const connector_5 = (
    <Box
      sx={{
        position: "absolute",
       
        left: "48px",
        top:'-109px',
        minHeight: "42px",
        background: "#FF690F",
       border:'1px solid #FF690F'
      }}
    >
        <Box sx={{position: 'absolute',
   bottom:'34px',
    minWidth: '11px',
    background: '#FF690F',
    borderRadius: '50px',
    minHeight: '11px',
    left: '-6px'}} > 

        </Box>
    </Box>
  );

  const containerElement = document.querySelector('.prompt-plus-container-chatai');
let adjustedPosition = ['50%', '50%']; // Default position if the element is not found

if (containerElement) {
  const rect = containerElement.getBoundingClientRect();
  adjustedPosition = [rect.left + 50, rect.top]; // Add 50 to the x-coordinate
}


export const interactiveTutorialAllSteps = {

   
    "quick-setup-main-container": {

        id: "quick-setup-main-container",        
        steps: [
        {
            selector: ".quick-start-setup", // CSS selector of the element
            content:(
                <>
                <Box sx={{position:'absolute',bottom:'15px',left:'43px'}} >
                   {connector}
                </Box>
                <Typography sx={{maxWidth:'390px'}}> Welcome to Simpla! To get you started, let’s set up your profile with just two quick details:</Typography>
               
                </>
            ),
            position:'top'
             
            
        },
       
    ]
    },

    "chatmode_selection": {

        id: "chatmode_selection",        
        steps: [
        {
            selector: ".modes-container-wrapper", // CSS selector of the element
            content: (
                <>
               
                 <Box sx={{position:'absolute',bottom:'15px',left:'-47px'}} >
                   {connector}
                </Box>
                <Typography sx={{maxWidth:'390px'}}>Select a conversation </Typography>
                
                </>
                
            ),
                position:'top'
        },
        ]
    },
    "enter_query":{
        id:'enter_query',
        steps: [
            {
              selector: '.prompt-plus-container-chatai',
              content: (
                <>
                <Box sx={{position:'absolute',bottom:'-28px'}} >
                   {connector_2}
                </Box>
                <Typography sx={{maxWidth:'390px'}}>  This is where you can ask all your queries.</Typography>
                
                </>
              ),
              position:'bottom'
              
              
             
              
            
            //prompt-plus-container-chatai
           
            },
            {
              selector: '.ButtonInput1', // Keep the spotlight on the container
              content: (
                <>
                  <Box sx={{position:'absolute',bottom:'0px'}} >
                   {connector_3}
                </Box>
                <Typography sx={{maxWidth:'390px'}}>Click here to submit</Typography>
              
                </>
              ),
          
            position:'top'
            
             
             
            },
          ],
          
    },

    "signoff-icon":{
            id:'signoff-icon',
            steps:[
                {
                    selector:'.signoff-icon',
                    content:'Click the Sign-Off button to see how you can validate Simpla’s advice with a human expert.'
                }
            ]
    },
    "sign-off-toast-modal":{
            id:'sign-off-toast-modal',
            steps:[
                {
                    selector:'.sign-off-toast-modal',
                    content:''
                }
            ]
    },

    "feedbackModalWrapper":{
            id:'feedbackModalWrapper',
            steps:[
                {
                    selector:'.feedbackModalWrapper',
                    content:(
                        <>
                       <Box sx={{position:'absolute',bottom:'15px'}} >
                   {connector}
                    </Box>
                <Typography sx={{maxWidth:'340px'}}>We'd love your feedback!</Typography>
                <Typography sx={{maxWidth:'340px'}}>Share your thoughts to help make Simpla even better.</Typography>
                        
                        </>
                    ),
                    position:'top'
                }
            ]
    },

    "desktop-profile-view":{
        id:'desktop-profile-view',
        steps:[
            {
                selector:'.profile-view-menu',
                content:(
                    <>
                        <Box sx={{position:'absolute',bottom:'12px'}} >
                   {connector_10}
                </Box>
                <Typography sx={{maxWidth:'390px'}}>Set up your profile, manage account settings, and view or update your subscription details, all in one place.</Typography>
                    
                    </>
                ),
                position:'left'
                
            }
        ]
    },

    "mobile-profile-view":{
        id:'mobile-profile-view',
        steps:[
            {
                selector:'.profile-view-menu',
                content:(
                    <>
                               <Box sx={{position:'absolute',bottom:'12px'}} >
                   {connector_10}
                </Box>
                <Typography sx={{maxWidth:'390px'}}>Set up your profile, manage account settings, and view or update your subscription details, all in one place.</Typography>
                    
                    </>
                ),
                
                position:'left'
            }
        ]
    },

    "view-sources":{
        id:'view-sources',
        steps:[
            {
                selector:'.view-sources',
                content:
                (
                    <>
                       <Box sx={{position:'absolute',bottom:'0px'}} >
                    {connector_5}
                 </Box>  
                 <Typography sx={{maxWidth:'390px'}}>Simpla has answered your query! Now, click the View Sources to see where this answer came from.</Typography>
                     
                    </>
                  
                ),
                position:'bottom'
               
            }
        ]
    },
    "chat-sources-container":{
        id:'chat-sources-container',
        steps:[
            {
                selector:'.chat-sources-container',
                content:'This is where you can find the original references and documents behind Simpla’s responses.'
            },
            {
                selector:'.pp-container',
                content:'Now click on the prompt plus icon'
            },
            
          
        ]
    },
    "suggested-prompts":{
        id:'suggested-prompts',
        steps:[
            {
                selector:'.opened-action-prompts',
                content:(
                    <>
                       <Box  sx={{position:'absolute',bottom:'0px',left:'-47px'}}  >
                    {connector_9}
                   
                 </Box>
                 <Typography sx={{maxWidth:'390px'}}>Prompt Plus also offers Suggested Prompts.</Typography>
                 <Typography sx={{maxWidth:'390px'}}>Click on any prompt below to explore a related conversation</Typography>
                
                    </>
                ),
                
                position:'top'
            },
         
        ]
    },
    "suggested-prompts-icon":{
        id:'suggested-prompts-icon',
        steps:[
            {
                selector:'.suggested-prompts-icon',
                content:'Now click on the suggested prompt icon'
            }
        ]
    },
    "action-prompts-div":{
        id:'action-prompts-div',
        steps:[
            {
                selector:'.prompt-plus-container-chatai',
                content:(
                    <>
                                                     <Box sx={{position:'absolute',top:'42px',left:'35px'}} >
                    {connector_12}
                 </Box>
                 <Typography sx={{maxWidth:'390px'}}>Welcome to Prompt Plus!</Typography>
                 <Typography sx={{maxWidth:'390px'}}>Let's check the Action Prompts first.</Typography>
                  
                    </>
                ),
                
                position:'top',
                highlightedSelectors:['.pp-container']
               
              
            }
        ]
    },
    "action-prompt-email":{
         id:'action-prompt-email',
         steps:[
            {
                selector:'.pseudo-accordion-main',
                content:(
                    <>
                       <Box sx={{position:'absolute',bottom:'0px',left:'-47px'}} >
                   {connector_9}
                </Box>
                <Typography sx={{maxWidth:'390px'}}>Select 'Write an email' from the list of available action prompts. </Typography>
                
                    </>
                ),
                
                position:'top'
            }
         ]   
    },

    "action-prompt-email-steps":{
        id:'action-prompt-email-steps',
        steps:[
           {
               selector:'.aim-field-email',
               content:(
                <>
                                                 <Box sx={{position:'absolute',top:'42px',left:'35px'}} >
                    {connector_12}
                 </Box>
                 <Typography sx={{maxWidth:'390px'}}>We have pre-selected "Clarify". This will help you craft an email with a clear message.</Typography>
                 
                </>
               )
               ,
               position:'top',
           },
           {
            selector:'.textbox-field',
            content:(
                <>
                                                    <Box sx={{position:'absolute',top:'42px',left:'35px'}} >
                    {connector_12}
                 </Box>
                 <Typography sx={{maxWidth:'390px'}}>Great! For this demo, we’ve pre-filled the email topic for you.</Typography>
                 
                </>
            ),
            
            position:'top',
           },
           {
            selector:'.to-from-container-desktop',
            content:(
                <>
                                                    <Box sx={{position:'absolute',top:'42px',left:'35px'}} >
                    {connector_12}
                 </Box>
                 <Typography sx={{maxWidth:'390px'}}>For the recipient, we’ve set it to John Doe by default, and the email will be sent from your company name.</Typography>
                 
                </>
            ),
            
            position:'top',
           },
           {
            selector:'.tone-slider-container',
            content:(
                <>
                                                  <Box sx={{position:'absolute',top:'67px',left:'35px'}} >
                    {connector_12}
                 </Box>
                 <Typography sx={{maxWidth:'390px'}}> Now, let’s personalise the email’s tone. Move the tone slider to the middle point between Friendly and Formal.</Typography>
                
                </>
            ),
            
            position:'top'
           }
        ]   
   },
   "generate-email-prompt":{
    id:'generate-email-prompt',
    steps:[
        {
            selector:'.generate-prompt-button-custom-en',
            content:(
                <>
                      <Box  sx={{position:'absolute',bottom:'0px',left:'-47px'}}  >
                    {connector_9}
                    
                 </Box>
                 <Typography sx={{maxWidth:'390px'}}>Finally, click on the Generate Prompt button to create your email. You’ll see how Simpla builds a clear, well-crafted message based on your input!</Typography>
                 
                </>
            ),
            
            position:'top',
        
        }
    ]
   },
   "email-generate-response":{
    id:'email-generate-response',
    steps:[
        {
            selector:'.chatLiveContent',
            content:(
                <>
                     <Box sx={{position:'absolute',top:'39px',left:'35px'}} >
                    {connector_7}
                    
                 </Box>
                 <Typography sx={{maxWidth:'390px'}}>Here’s the email Simpla generated based on your input! It’s ready for use or export.</Typography>
                
                </>
            ),
            
            position:'bottom',
        }
    ]
   },
   

    "history-button":{
        id:'history-button',
        steps:[
            {
                selector:'.history-button',
                content:(
                    <>
                            <Box sx={{position:'absolute',top:'0px'}} >
                    {connector_4}
                 </Box>  
                 <Typography sx={{maxWidth:'390px'}}>Next, let’s explore how you can manage your past conversations using the History feature. Click the History icon from the menu to get started.</Typography>
                    
                    </>
                ),
                position:'bottom',
            },
            
        ]

    },
    "history-chats":{
        id:'history-chats',
        steps:[
            {
                selector:'.AiChats',
                content:
                (
                    <>
                                <Box sx={{position:'absolute',top:'0px',left:'140px'}} >
                    {connector_4}
                 </Box>  
                 <Typography sx={{maxWidth:'390px'}}>Here’s one of your conversations. In the History tab, all your previous interactions are stored neatly. You can always refer back to them whenever you need to.</Typography>
                    
                   
                    </>
                ),
                 position:'bottom'
              
            },
            {
                selector:'.chat-toggle-icon',
                content:
                (
                        <>
                                          <Box sx={{position:'absolute',top:'41px',left:'-111px'}} >
                    {connector_7}
                 </Box>
                 <Typography sx={{maxWidth:'390px'}}>Now, let’s mark this conversation as a favourite! Click the 3 dots next to it and select 'Mark as favourite.' This helps you quickly find important conversations later.</Typography>  
                        
                        </>
                )
                
            }
        ]
    },
    "mark-favourite":{
        id:'mark-favourite',
        steps:[
            {
                selector:'.mark-favourite-icon',
                content:
                (
                <>
                                           <Box sx={{position:'absolute',top:'42px',left:'-11px'}} >
                    {connector_7}
                 </Box>
                 <Typography sx={{maxWidth:'390px'}}> You can click on this icon to mark the conversation as favourite!</Typography>  
               
                </>
                ),
                position:'bottom'
                
            },{
                selector:'.history-search',
                content:
                (
                    <>
                                                 <Box sx={{position:'absolute',top:'42px',left:'35px'}} >
                    {connector_7}
                 </Box>  
                 <Typography sx={{maxWidth:'390px'}}>See the search bar at the top? You can type keywords or topics here to instantly locate specific conversations.</Typography>
                 
                    </>
                ),
               
                position:'bottom'
            },
            {
                selector:'.filterbtn',
                content:
                (
                    <>
                          <Box sx={{position:'absolute',top:'42px',left:'-116px'}} >
                    {connector_7}
                 </Box> 
                 <Typography sx={{maxWidth:'390px'}}>This will allow you to filter all conversations that were marked as favourites.</Typography> 
                
                    </>
                ),
                
                position:'bottom'
            },
            {
                selector:'.help-button',
                content:
                (
                        <>
                                  <Box sx={{position:'absolute',top:'42px',left:'-97px'}} >
                    {connector_7}
                 </Box>  
                 <Typography sx={{maxWidth:'390px'}}>Let’s explore how Simpla supports you through its Help Tour feature. Start by clicking the Help Tour icon in the menu.</Typography>
                 
                        </>
                ),
                position:'bottom'
                
                
            },
          
        ]
    },
    "help-tutorial":{
        id:'help-tutorial',
        steps:[
            {
                selector:'.vediobtn',
                content:
                (
                    <>
                                          <Box sx={{position:'absolute',bottom:'12px'}} >
                    {connector_11}
                 </Box> 
                 <Typography sx={{maxWidth:'390px'}}>First, we have the Video Tutorials that helps you access quick, guided videos that walk you through essential features and how to use them.</Typography>
                 
                    </>
                ),
                position:'right'
                
            },
            {
                selector:'.chatbtn',
                content:
                (
                    <>
                                              <Box sx={{position:'absolute',bottom:'12px'}} >
                    {connector_11}
                 </Box> 
                 <Typography sx={{maxWidth:'390px'}}> Next, you’ll find the FAQs which answers common questions, helping you troubleshoot and understand key features at any time.</Typography>
                
                    </>
                ),
                position:'right'
                
            },
            {
                selector:'.tourbtn',
                content:
                (
                    <>
                      <Box sx={{position:'absolute',bottom:'-5px'}} >
                   {connector_11}
                </Box>
                <Typography sx={{maxWidth:'390px'}}>Lastly, is the Product Tour—the interactive walkthrough you’re currently using. You can access this to restart the tutorial anytime.</Typography>
                
                    </>
                ),
                
                position:'right'
            },
            {
                selector:'.add-btn',
                content:'Now Let’s explore how Simpla supports you through starting a new chat in any module. Start by clicking the New Chat icon in the menu. '
            }
        ]
    },
    "new_chat":{
        id:'new_chat',
        steps:[
            {
                selector:'.advise-btn',
                content:(
                    <>
                                          <Box sx={{position:'absolute',bottom:'-30px'}} >
                    {connector_11}
                 </Box>
                 <Typography sx={{maxWidth:'390px'}} > Click here to start a new chat in our Advise module   </Typography> 
                 
                    </>
                ),
                position:'right'
                
            },
            {
                selector:'.upload',
                content:(
                    <>
                                                 <Box sx={{position:'absolute',bottom:'-18px'}} >
                    {connector_11}
                 </Box> 
                 <Typography sx={{maxWidth:'390px'}}>Our Process module is currently in beta mode but feel free to check it out</Typography>
                 
                    </>
                ),
                position:'right'
                
            },
            {
                selector:'.tabler',
                content:(
                    <>
                                         <Box sx={{position:'absolute',bottom:'-18px'}} >
                    {connector_11}
                 </Box> 
                 <Typography sx={{maxWidth:'390px'}}>Same with our Analyse module -- continuously evolving. Give it a try!</Typography>
                 
                    </>
                ),
                position:'right'
                
            }
        ]
    },
    "tour_skipped":{
        id:'tour_skipped',
        steps:[
            {
                selector:'.tourbtn-skipped',
                content:(
                    <>
                                                <Box sx={{position:'absolute',bottom:'-18px'}} >
                    {connector_11}
                 </Box>
                 <Typography sx={{maxWidth:'390px'}}>Tour skipped! Click the Product Tour icon anytime to restart.</Typography> 
                 
                    </>
                ),
                
                position:'right'
            }
        ]
    },
    "profile-incomp":{
        id:'profile-incomp',
        steps:[
            {
                selector:'.profile-incomp',
                content:(
                    <>
                       <Box sx={{position:'absolute',bottom:'15px',left:'30px'}} >
                   {connector}
                    </Box>
                    <Typography sx={{maxWidth:'390px'}}>Provide two quick details to get personalised tax advice.</Typography>
                   
                    </>
                ),
                position:'top'
            }
        ]
    },
    "heads-up":{
        id:'heads-up',
        steps:[
            {
                selector:'.cross-icon',
                content:(
                    <>
                          <Box sx={{position:'absolute',bottom:'15px',left:'-118px'}} >
                   {connector}
                    </Box>
                    <Typography sx={{maxWidth:'390px'}}> Click X to close</Typography>
                    
                    </>
                ),
                position:'top'
            }
        ]
    }
}

export const getCurrentSteps=(id)=>{

}

export const getTutorialStepsById = (id) => {
    const tutorial = interactiveTutorialAllSteps[id];
    return tutorial ? tutorial.steps : null;
};

const initialValue = {
step_active:'',
step_no:null
} 


const interactiveTutorialSlice = createSlice({
    name:'interactiveTutorialSteps',
    initialState: initialValue,
    reducers: {
        setInteractiveTutorialSteps: (state, action) => {

            state.step_active=action.payload.id;
            state.step_no=action.payload.no;   
        },
    }
})

export const {setInteractiveTutorialSteps} = interactiveTutorialSlice.actions;
export default interactiveTutorialSlice.reducer