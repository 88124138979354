import { createSlice } from "@reduxjs/toolkit";

const tourButtonSlice=createSlice({
    name:'tour-button-slice',
    initialState:{
        open_help:false
    },
    reducers:{
        toggleTourButton:(state,action)=>{
            state.open_help=true;
        },
        toggleTourButtonOff:(state,action)=>{
            state.open_help=false;
        }
    }
})

export const {toggleTourButton,toggleTourButtonOff}=tourButtonSlice.actions;
export default tourButtonSlice.reducer;