import { Spinner } from "react-bootstrap"
import { SuccessCheckmark } from "../../assets/SVG/svg"
import { useTracking } from "react-tracking"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import "./QuickProfileSetup.css"

const ProfileComplete = (props) => {
    //props?.country_selected

    const {t} = useTranslation()
    const {trackEvent} = useTracking()
    const navigate = useNavigate()
    const dispatch = useDispatch();
    // { code: 'SA', label: 'KSA', phone: '966' },
    // { code: 'BH', label: 'Bahrain', phone: '973' },
    // { code: 'JO', label: 'Jordan', phone: '962' },
    // { code: 'OM', label: 'Oman', phone: '968' },
    // { code: 'QA', label: 'Qatar', phone: '974' },
    // { code: 'EG', label: 'Egypt', phone: '20' },
    
    const whitelist={
      first_level_countries:['uae','ksa'],
      second_level_countries :['bahrain','oman','jordan','qatar','egypt']
    }
    

    const [loading, setLoading] = useState(false);

    if  (loading) {
        return(<>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100vw", height: "100vh", background: "#faf5e1", paddingBottom: "10%" }}>
            <Spinner />
        </div></>)
    }
    else{
    return(<>

    <div className="success-parent-container">
    <div className="success-main-container">

      <div style={{width:"100%"}} className="success-container">
        <div className="success-icon">
            <SuccessCheckmark size="75" color="#fe6100" />
        </div>
        <div>
            <h3 className="thank-you-message">
            {t("profile_setup")} <br/> {t("complete")}
            </h3>
        </div>
      </div>

        <div style={{paddingTop:'30px'}} >
      <p className='plan-description' style={{textAlign:'center',fontSize:'19px',paddingInline:'10px'}} > {whitelist.second_level_countries.includes(props?.country_selected.toLowerCase()) ? `We’re excited to have you on board! Your country is supported in beta mode, so some features might still be evolving. Dive into Simpla and experience what’s available today!` : whitelist.first_level_countries.includes(props?.country_selected.toLowerCase()) ? 'Awesome! Your country is fully supported. Explore Simpla and enjoy all its features designed to simplify your journey!' :`Tax features coming soon to your country. Meanwhile, discover what Simpla's accounting conversation can do for you` }  </p>
      </div>
    

      <div style={{paddingTop:"30px"}}>
        <p className="plan-description" style={{textAlign:"center"}}>{t("get_started")}</p>
      </div>




      <div style={{}} className="button-container button-padding">
        <div className="custom-button" onClick={()=>{
            navigate("/")
            }}>
                {t("letsgo")}
        </div>
      </div>


    </div>        
    </div>




    </>)}
}

export default ProfileComplete