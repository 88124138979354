import { setApiCount } from "../ReduxSlices/ApiCountSlice";
import { setCurrentPlan, setCurrentPlanId, setCurrentPlanInfo, setPlanPaidStatus, setPlanSubStatus, setPlanValidity } from "../ReduxSlices/CurrentPlanSlice";
import { SERVICES } from "../utils/ConstantSettings";
import axios from "axios";
import { setProfileCompleteModalState, setProfileCompletion, setProfileId } from "../ReduxSlices/ProfileCompletionSlice";
import { setQuestionerFilled } from "../ReduxSlices/QuestionerStatusSlice";
import { setSignOffCredits } from "../ReduxSlices/SignOffCreditsSlice";
import { localStorageValueTester } from "../utils/UtilityFunctions";

async function fetchApiCount(dispatch, trackEvent){

  if (localStorageValueTester(localStorage.getItem('token')) === true){
    axios.get(`${SERVICES.apiBaseUrl}/api_usage/getApiCount`, {headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
    }})
    .then((res)=>{
        // console.log("fetch api count successful")
        dispatch(setApiCount(`${res.data?.object?.remaining_credits}`))

        if (`${res.data?.object?.plan_name}`?.toLowerCase() === "questioner"){
          dispatch(setCurrentPlan(`Trial`))
        }
        else{
          dispatch(setCurrentPlan(`${res.data?.object?.plan_name}`))
        }

        dispatch(setCurrentPlanInfo({...res.data?.object}))

        // dispatch(setCurrentPlanId(`${res.data?.object?.id}`))
        // dispatch(setPlanPaidStatus(`${res.data?.object?.paid_plan}`))
        // dispatch(setPlanSubStatus( res.data?.object?.is_subscribed ) )
        // dispatch(setPlanValidity(`${res.data?.object?.valid_date}`))
        // dispatch(setCurrentPlanId(`${res.data?.object?.id}`))
        localStorage.setItem("apiCounter", `${res.data?.object?.remaining_credits}`)
    })
    .catch((error)=>{
        trackEvent({
            event_type: "error", 
            event_name:"Failed to fetch Api Counts (Remaining Simpla Uses)", 
            event_data:{
              error: `${error}`
            }
          })       
        })         
  } 
  
}

function getProfileCompletionStatus(dispatch, id = localStorage.getItem("profileid"), previousProfileStatus = "exclude"){

  if (localStorageValueTester(localStorage.getItem('token')) === true){
      let statusBool = false;

      if (id !== null && id !== "null" && id !== undefined && id !== "undefined"){
          axios.get(`${SERVICES.apiBaseUrl}/user/profile_status/${id}`, 
          {headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          }})
          .then((res)=>{
            try{
              let status = JSON.parse(res.data?.object)?.profile_complete;

              if (status == "true" || status == true){
                statusBool = true
                dispatch(setProfileCompletion(true));
                dispatch(setProfileId(id))
                if (previousProfileStatus === "false" || previousProfileStatus === false){
                  dispatch(setProfileCompleteModalState(true))
                }
              }
              else{
                dispatch(setProfileCompletion(false))
                dispatch(setProfileId(id))
              }
            }
            catch(error){
              dispatch(setProfileCompletion(false))
              dispatch(setProfileId(id))
            }
          })
          .catch((error)=>{      
            dispatch(setProfileCompletion(false))
            //dispatch(setProfileId(id))
          })

          }

      return statusBool    
  }


}

function profileCompletionCheck(dispatch, profile_complete, id = localStorage.getItem("profileid"), previousProfileStatus = "exclude"){

  let status = profile_complete
  let openUpdateDialog = false

      if (status == "true" || status == true){
        status = true
        dispatch(setProfileCompletion(true));
        dispatch(setProfileId(id))
        openUpdateDialog = true
        if (previousProfileStatus === "false" || previousProfileStatus === false){
          openUpdateDialog = false
          dispatch(setProfileCompleteModalState(true))
        }
      }
      else{
        dispatch(setProfileCompletion(false))
        dispatch(setProfileId(id))
        openUpdateDialog = true
      }

      return {status, openUpdateDialog}
}

function fetchSimplaEnterpriseMailStatus(trackEvent){

  if (localStorageValueTester(localStorage.getItem('token')) === true){
      axios.get(`${SERVICES.apiBaseUrl}/api_usage/getPremiumRequestStatus`, 
      {headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      }})
        .then((res)=>{
          let status;
          try {
            status = JSON.parse(res.data?.object)?.status
          }
          catch(error){
            localStorage.setItem("simpla_enterprise_email_sent", false )
          }
          localStorage.setItem("simpla_enterprise_email_sent", status )
        })
        .catch((error)=>{
          trackEvent({
            event_type: "error", 
            event_name:"Failed to get sent status of get-in-touch email", 
            event_data:{
              error: `${error}`
            }
          })
        })    
  }

}

async function questionaireStatus(dispatch, trackEvent){
  axios.get(`${SERVICES.apiBaseUrl}/api_usage/getQuestioneerStatus`, {
    headers: {
    'Authorization': `Bearer ${localStorage.getItem('token')}`,
    'Content-Type': 'application/json; charset=UTF-8'
  }})
    .then((res)=>{
      //console.log(res.data.questioneer_filled)

      let temp = {}
      
      try {
        temp = JSON.parse(res.data)
        localStorage.setItem("questioner_filled", `${temp?.questioner_filled}`)
        dispatch(setQuestionerFilled(temp?.questioner_filled))
      }

      catch (error) {
        //console.log("Unable to parse data")
      }
      
    })
    .catch((error)=>{
      //console.log(error)
      trackEvent({
        event_type: "error", 
        event_name:"Failed to get survey form questionaire status (whether it is filled or unfilled)", 
        event_data:{
          error: `${error}`
        }
      })
    })

}

async function getSignOffCredits(dispatch){

  if (localStorageValueTester(localStorage.getItem('token')) === true){
      axios.get(`${SERVICES.apiBaseUrl}/signoff/get_credits`, 
      {headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      }})
      .then((res)=>{
        try{
          let credits = JSON.parse(res.data.message)?.signoff_credits;
          dispatch(setSignOffCredits(`${credits}`));
        }
        catch(error){
          dispatch(setSignOffCredits("0"))
        }
      })
      .catch((error)=>{dispatch(setSignOffCredits("0"))})    
  }

}
    
export {fetchApiCount, fetchSimplaEnterpriseMailStatus, getProfileCompletionStatus, questionaireStatus, getSignOffCredits, profileCompletionCheck}
