import { useEffect, useState } from "react";

import "./StarterPlan.css"
import "./PaymentPlan.css"

import { CheckMarkBlack, EncircledCrossExit } from "../../assets/SVG/svg";
import { useTranslation } from "react-i18next";

import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


import { useTracking } from "react-tracking";
import { useNavigate,} from "react-router-dom";
import { SERVICES } from "../../utils/ConstantSettings";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { PlanNavbar } from "./PlanNavbar";
import { matchMaker } from "./SubscriptionTermsTranslator";
import { useSelector } from "react-redux";
import { payment_plans } from "./DummyData";
import ButtonLoader from "../../components/common/Button/ButtonLoader";
import { Notification } from "../../utils/Notification";

  export function planNameSplitter(name){
      if (name.includes("-")){
        return name.split("-")[0]
      }
      else return name
    }

  const PaymentPlan = () => {
    const currentPlanObj = useSelector((state) => state.currentPlan);
    console.log(currentPlanObj);
    const [openDialog,setOpenDialog]=useState(false);
    const [plan,setPlan]=useState({});
    const [btnLoading,setBtnLoading]=useState(false);
    
    const {trackEvent} = useTracking()
    const {t} = useTranslation()
    const navigate = useNavigate()

    const blackLayout = ["Growth"]
    const standardLayout = ["Trial", "Base", "Starter", "Enterprise"]



//   useEffect(async ()=>{
//     const stripe = require('stripe')('sk_test_51NwQaICJ8fVfquVPcYjkC9CCIXERS0GxXJblj6M5yz31TrT5dNjo9EKv6RFcbbmkcERxVrH3SWVgDimihXezvnns00infgKpHH');
//     const product = await stripe.products.retrieve('prod_NWjs8kKbJWmuuc');
//   }, [])

    // const [planType, setPlanType] = useState ("Monthly")
    // const [planAmount, setPlanAmount] = useState({
    //     Monthly: "34.99",
    //     Annual: "359.88",
    // })

    const [loading, setLoading] = useState(true)
    const [planContainerWidth, setPlanContainerWidth] = useState("")

    const [notification, setNotification] = useState({
      open: false,
      variant: "",
      message: "",
    });
    const setNotifications = (open=false, variant="", msg="") => {
      setNotification({ open: open, variant: variant, message: msg });
    };

    useEffect(()=>{

        const token=((localStorage?.getItem('token')));

        if (!token){
            navigate("/login")
        }
        else{
        axios.get(`${SERVICES.apiBaseUrl}/api_usage/getSubscriptionPlans`, 
          {headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          }})
          .then((res)=>{
            setPaymentPlans(res.data.message)
            
            setLoading(false)

            // let planContainer = document.querySelector('.plan-container-parent');
            // let width = planContainer.offsetWidth;
            // console.log('Width:', width);
            // setPlanContainerWidth(`${width}`)
          })
          .catch((error)=>{})            
        }


    },[])

    const [paymentPlans, setPaymentPlans] = useState([...payment_plans]) 

    function navigateToPlan(plan, id, plan_info,delay){

        // console.log("plan ",plan)

        const childPlans = paymentPlans.filter((plan) => plan.parent_id === id )
        //console.log("child plans = ",childPlans)

        if (plan === "Starter"){
            navigate("/subscription-details", {state: {plan_id: `${id}`, plan_info: plan_info, childPlans: [...childPlans]}})
        }
        else if (plan === "Trial"){

            //console.log(plan, localStorage.getItem("profile"))
            
            if (localStorage.getItem("profile") == false || localStorage.getItem("profile") == "false" ){
                navigate("/quicksetup")
            }
            else{
              console.log(delay);
                if(delay){
                  setTimeout(()=>{
                      navigate('/')
                  },2000)
                }
                else{
                  navigate('/') 
                }
                // window.location.reload();
            }
        }
        else if(plan === "Enterprise"){
            discoverSimplaSendEmail()
        }
        else if (childPlans){
            navigate("/subscription-details", {state: {plan_id: `${id}`, plan_info: plan_info, childPlans: [...childPlans]}})
        }

    }



    // Enterprise modal pop up:
    const [showEnterpriseModal, setShowEnterpriseModal] = useState(false);
    const handleShowEnterpriseModal = () => {
      setShowEnterpriseModal(true)
    };
    const handleCloseEnterpriseModal = () => setShowEnterpriseModal(false);

    function discoverSimplaSendEmail(sendOption="email"){
      
        setShowEnterpriseModal(true)
      
        if (localStorage.getItem("simpla_enterprise_email_sent") == "false"){
            axios.post(`${SERVICES.apiBaseUrl}/api_usage/createPremiumRequest`, {notification_method: `${sendOption}`}, 
              {headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json; charset=UTF-8',
      
              }})
              .then((res)=>{
                let status;
                try {
                  status = JSON.parse(res.data?.object)?.status
                  localStorage.setItem("simpla_enterprise_email_sent", status )
                }
                catch(error){
                  localStorage.setItem("simpla_enterprise_email_sent", true )
                  trackEvent({
                    event_type: "error", 
                    event_name:"Failed to get/parse get-in-touch email status (response of API was not parsed)", 
                    event_data:{
                      error: `${error}`
                    }
                  })
                }
      
                if (res.data?.message === "Success" || res.data?.message === "success"){
                  localStorage.setItem("simpla_enterprise_email_sent", true )
                }
              })
              .catch((error)=>{
                trackEvent({
                  event_type: "error", 
                  event_name:"Failed to send get-in-touch email request", 
                  event_data:{
                    error: `${error}`
                  }
                })
              })
        }
       
      }

      function priceNote(plan){

        if(plan?.metadata.custom_plan === true){
          return "talk_to_team"
        }
        else if (blackLayout.includes(plan.name) || plan.name !== "Trial"){
          return "billed_annually"
        }
        else{
          return ""
        }
      } 

    const currentPlan = useSelector((state)=>state.currentPlan)



    function paymentPlanButtonText(btn){
      if(`${btn}`?.toLowerCase()?.includes("continue simpla trial")){
        if (`${planNameSplitter(currentPlan?.name)}`?.toLowerCase() === "trial"){
          return matchMaker(btn)
        }
        else{
          return t("try_simpla_trial")
        }
      }
      else if(`${btn}`?.toLowerCase()?.includes("select simpla starter") && `${planNameSplitter(currentPlan?.name)}`?.toLowerCase()?.includes("starter")){
        return t("continue_starter")
      }
      else {
        return matchMaker(btn)
      }
    }

    return(<>

    <PlanNavbar/>

        {
            loading ? <>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100vw", height: "100vh", background: "#faf5e1", paddingBottom: "10%" }}>
                <Spinner/>
            </div></> : <>



        <div className="grand-parent-plan-container">
        
                <div className="plan-container-parent" style={{display:"flex", flexDirection:"column"}}>

                <div className="select-plan-heading-container">
              <h4 className="select-plan-heading">{t("select_your_plan")}</h4>
              </div>

              <div  className="plan-container-child">

                {
                    paymentPlans?.filter((item)=> item.name !== "Questioner" && item.parent_id === null || item.parent_id === "null")?.map((plan,index)=>(
                        <>
                        <div className="child-wrapper">

                        <div className="plan-container">
                        {( blackLayout.includes(plan.name) || planNameSplitter(currentPlan?.name) === (plan.name) || (plan.name?.toLowerCase()?.includes("trial") && `${planNameSplitter(currentPlan?.name)}`?.toLowerCase() === "trial")) && <> 
                        <div className={`${ (plan.name?.toLowerCase()?.includes("trial") && `${planNameSplitter(currentPlan?.name)}`?.toLowerCase() === "trial") || (planNameSplitter(currentPlan?.name) === plan.name && !blackLayout.includes( planNameSplitter(plan.name)))  ? "chip-label-current-plan" : "suggested-plan"} ${ localStorage.getItem("i18nextLng") === "ar" ? "suggested-plan-placement-ar" : "suggested-plan-placement-en"}`}> { (planNameSplitter(currentPlan?.name) === (plan.name) || (plan.name?.toLowerCase()?.includes("trial") && `${planNameSplitter(currentPlan?.name)}`?.toLowerCase() === "trial")) ? t("current_plan") : t("suggested_plan")} </div>
                        </>}
                        <div className={`plan-type-container plan-type-container-dimensions ${(blackLayout.includes(plan.name) || planNameSplitter(currentPlan?.name) === plan.name || (plan.name?.toLowerCase()?.includes("trial") && `${planNameSplitter(currentPlan?.name)}`?.toLowerCase() === "trial")) ? "chip-label-plan-type-container-height" : ""}`} style={{height:`${blackLayout.includes(plan.name) || planNameSplitter(currentPlan?.name) === plan.name ? "123px" : ""}`, marginTop:`${blackLayout.includes(plan.name) || planNameSplitter(currentPlan?.name) === (plan.name) ? "-6px" : ""}`}}>
                        <h2 className={`starter-plan-heading ${blackLayout.includes(plan.name) || planNameSplitter(currentPlan?.name) === plan.name || (plan.name?.toLowerCase()?.includes("trial") && `${planNameSplitter(currentPlan?.name)}`?.toLowerCase() === "trial") ? "chip-label-heading" : ""}`} >{matchMaker(plan.name)}</h2>

                        <p className="plan-description" style={{padding: `${localStorage.getItem("i18nextLng") === "ar" ? "0px 7px 0px 0px" : "0px 0px 0px 7px"}`}}>{matchMaker(plan.metadata.plan_description)}</p>
                            
                        </div>

                        <div className="plan-price-container plan-price-container-dimensions" style={{paddingTop:`${blackLayout.includes(plan.name) || planNameSplitter( currentPlan?.name) === plan.name ? "20px" : ""}`}}>

                            <div className="column-div">
                            <div className="div-row">
                            <h2 > { plan?.metadata.custom_plan === true ? matchMaker("Ask us") : plan?.metadata?.price_text?.length ? matchMaker(plan?.metadata?.price_text) : `$${plan?.price}` } </h2>
                            {plan?.metadata.custom_plan === true ? "" : <p style={{paddingTop: `${plan?.metadata?.price_note?.length ? "3px" : ""}`}} className="amount-per-month plan-description">{ plan?.metadata?.price_note?.length ? matchMaker(plan?.metadata?.price_note) : t("usd_per_mo")}</p>  }
                            </div>

                            <div className="total-amount-note-container">
                            <p className="total-amount-note plan-description feature-p" style={{ display:"flex", alignSelf:"baseline", fontWeight:"400"}}> {t(priceNote(plan))} </p>
                            </div>
                            
                            </div>

                            
                        </div>

                        <div className="payment-plan-features-container plan-features-container-dimensions" style={{paddingTop: `${blackLayout.includes(plan.name) || planNameSplitter(currentPlan?.name) === plan.name ? "30px" : ""}`}}>

                        {
                            plan.metadata.plan_features.map((feature)=>(
                                <div className="module-use-info-div" style={{ paddingTop:"0%", lineHeight:"20px", fontWeight: 500, }}>
                                
                                <div className="checkmark-container">
                                <CheckMarkBlack width={30} height={30} color={"black"}/> 
                                </div>
                                
                                <p className="feature-p" style={{margin: `${localStorage.getItem('i18nextLng')?.toString() === "ar" ? "1% 4% 0% 0%" : "1% 0% 0% 2%"}`}}>{matchMaker(feature)}</p>
                                </div>  
                            ))
                        }


                        <div className="grey-note-div">
                            <p className={`${localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "grey-note-ar" : "grey-note"}`}>{matchMaker(plan.metadata.end_note)}</p>
                        </div>

                        </div>
                        



                        <div className={`button-container button-container-custom ${ planNameSplitter(currentPlan?.name) === plan.name && !blackLayout.includes(planNameSplitter(currentPlan?.name)) ? "orange-current-plan-padding" : ""}`} style={{paddingTop: `${blackLayout.includes(plan.name)  ? "60px" : `${ plan.name?.toLowerCase()?.includes("trial") && `${planNameSplitter(currentPlan?.name)}`?.toLowerCase() === "trial" ? "55px" : "60px"}`}`}}>
                            <div onClick={()=>{ console.log(index); setPlan(plan);  if(currentPlanObj?.is_subscribed === true && index ===0 ){return setOpenDialog(true)} navigateToPlan(plan.name, plan.id, plan)}} className={`${ blackLayout.includes(plan.name)  ? "black-button-style" : "custom-button"} ${localStorage.getItem("i18nextLng") === "ar" ? "button-height-ar" : ""}`}>{ paymentPlanButtonText(plan.metadata.button_text) }</div>
                        </div>

                        </div> 
                        </div>
                    
                        </>

                    ))
                }                    
              </div>
                
    
                </div>            
        </div>
        </>}

{/* We will be in touch modal */}
<Dialog
  open={showEnterpriseModal}
  onClose={handleCloseEnterpriseModal}
  fullWidth
  maxWidth="sm"

  PaperProps={{
    sx: {
      minHeight: "max-content",
      borderRadius: "15px",
      background: "#fdfbf5",


    }
  }}
>
  <DialogTitle>


    {/* <CloseIcon onClick={handleModalClose} style={{ cursor: 'pointer', position: 'absolute', top: "5%", right: "3%" }} /> */}
    {/* <div className="close-circle" 
        style={{ 
            position: 'absolute', 
            top: '11.5%', 
            right: localStorage.getItem('i18nextLng') === "ar" ? 'auto' : '2%', 
            left: localStorage.getItem('i18nextLng') === "ar" ? '2%' : 'auto', 
            transform: 'translateY(-50%)', 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            cursor: 'pointer' 
        }}>
        <CloseIcon 
            fontSize="normal" 
            onClick={() => setShowEnterpriseModal(false)} 
            style={{ margin: 0 }} 
        />
       </div> */}
             <div className="" 
         style={{ 
            position: 'absolute', 
            top: "1.5%", 
            right: `${ localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "" : "1.5%"}`, 
            left:  `${ localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "1.5%" : ""}`, 
            display:"flex", 
            justifyContent:"center", 
            alignItems:"center", 
            cursor:"pointer" 
            }}>

      <div 
          onClick={() => setShowEnterpriseModal(false)} 
          style={{ marginTop: "3px", cursor: 'pointer', }} >
        <EncircledCrossExit />        
       </div>

      </div>
  </DialogTitle>

  <DialogContent>

    <div style={{padding:"5%"}}>

    <h6 className="more-uses-heading" style={{ fontWeight: "bold", fontFamily: "karla", textAlign: "center" }}>
      {t("in_touch_modal")}
    </h6>

    <p style={{ fontFamily: 'karla', fontWeight: 600, textAlign: 'center', lineHeight:"20px" }}>
      {t("in_touch_modal_description")}
    </p>

    </div>


  </DialogContent>


</Dialog>


<Dialog open={openDialog} onClose={()=>setOpenDialog(false)}  PaperProps={{
    sx: {
      minHeight: "max-content",
      borderRadius: "15px",
      background: "#fdfbf5",

    }
  }}
  sx={{ "& .MuiPaper-root":{
    minHeight:'250px !important',
    minWidth:'400px !important'
  }}}

   >

<DialogTitle>
<Typography variant='h6' sx={{marginTop:'4px'}} >Downgrade Plan</Typography>
<div className="" 
         style={{ 
            position: 'absolute', 
            top: "1.5%", 
            right: `${ localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "" : "1.5%"}`, 
            left:  `${ localStorage.getItem('i18nextLng')?.toString() ===  "ar" ? "1.5%" : ""}`, 
            display:"flex", 
            justifyContent:"center", 
            alignItems:"center", 
            cursor:"pointer" 
            }}>

      <div 
          onClick={() => setOpenDialog(false)} 
          style={{ marginTop: "3px", cursor: 'pointer', }} >
        <EncircledCrossExit />        
       </div>

      </div>

</DialogTitle>

<DialogContent>
            <Typography sx={{fontSize:'19px',marginTop:'20px'}} textAlign={'center'} > Are you sure you want to downgrade your plan? This action cannot be undone </Typography>
            <Box mt={2} sx={{display:'flex',justifyContent:'center',gap:'10px'}} > 
            <Button variant='contained' sx={{textTransform:'none',minWidth:'150px',borderRadius:'50px',background:'black',color:'white',"&:hover":{background:'white !important',
    color: '#fe6100 !important'}}} onClick={()=>setOpenDialog(false)} > Cancel </Button>
            <Button variant='contained' disabled={btnLoading ? true : false} sx={{ background:'#fe6100', textTransform:'none',minWidth:'150px',borderRadius:'50px',"&:hover":{background:'white !important',
    color: '#fe6100 !important',
    }}} onClick={()=>{
        if(btnLoading === false){
          setBtnLoading(true);
          axios.post(`${SERVICES.apiBaseUrl}/payments/subscription_cancel`,  {},
            {headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
            }}).then((res)=>{
              setNotifications( true, "success", res?.data?.message);
              navigateToPlan(plan.name, plan.id, plan,'add-delay');
            })

            .catch((error)=>{
              
                //console.log(error.response.data.error)
        
                if (error.response.data.error === "User does not have an active subscription" || error.response.data.error === "User is not a customer on stripe"){
                    // handleShowToastModal()
                }
                else{
                  setNotifications( true, "error", error?.response?.data?.error);
                }
              
                
            }).finally(()=>{
              setBtnLoading(false);
            })
        }
    

      
    }}  > {btnLoading == true ? <ButtonLoader /> : 'Confirm' } </Button>
            </Box>
</DialogContent>



</Dialog>

{notification.open && (
          <Notification
            open={notification.open}
            variant={notification.variant}
            message={notification.message}
            setNotifications={setNotifications}
          />
        )}




    </>)



  }

  export default PaymentPlan;