const all_tax_options = [
          
    // {
    //     labelEnglish: "Value-Added Tax (VAT)",
    //     valueEnglish: "Value-Added Tax (VAT)",
    //     labelArabic: t("vat_option"),
    //     valueArabic: t("vat_option")
    // },
    // {
    //     labelEnglish: "Corporate Income Tax (CIT)",
    //     valueEnglish: "Corporate Income Tax (CIT)",
    //     labelArabic: t("cit_option"),
    //     valueArabic: t("cit_option")
    // },
    // {
    //     labelEnglish: "Withholding Tax (WHT)",
    //     valueEnglish: "Withholding Tax (WHT)",
    //     labelArabic: t("wht_option"),
    //     valueArabic: t("wht_option")
    // },
    // {
    //     labelEnglish: "Excise Tax",
    //     valueEnglish: "Excise Tax",
    //     labelArabic: t("excise_tax_option"),
    //     valueArabic: t("excise_tax_option")
    // },
    // {
    //     labelEnglish: "Personal Income Tax (PIT)",
    //     valueEnglish: "Personal Income Tax (PIT)",
    //     labelArabic: t("pit_option"),
    //     valueArabic: t("pit_option")
    // },
    // {
    //     labelEnglish: "Zakat",
    //     valueEnglish: "Zakat",
    //     labelArabic: t("zakat_option"),
    //     valueArabic: t("zakat_option")
    // }
]

export const countryWhitelist = [
    "uae",
    "ksa",
    "jordan",
    "bahrain",
    "oman",
]

export const countryBasedTaxOptions = (country_option, t) => {

    const country = `${country_option}`?.toLowerCase() || localStorage.getItem("profile_country")?.toLowerCase() || "uae"

    if (country === "uae"){
        return [
            
            {
                labelEnglish: "Value-Added Tax (VAT)",
                valueEnglish: "Value-Added Tax (VAT)",
                labelArabic: t("vat_option"),
                valueArabic: t("vat_option")
            },
            {
                labelEnglish: "Corporate Income Tax (CIT)",
                valueEnglish: "Corporate Income Tax (CIT)",
                labelArabic: t("cit_option"),
                valueArabic: t("cit_option")
            },
            {
                labelEnglish: "Excise Tax",
                valueEnglish: "Excise Tax",
                labelArabic: t("excise_tax_option"),
                valueArabic: t("excise_tax_option")
            },
        ]
    }
    else if (country === "ksa"){
        return [
          
            {
                labelEnglish: "Value-Added Tax (VAT)",
                valueEnglish: "Value-Added Tax (VAT)",
                labelArabic: t("vat_option"),
                valueArabic: t("vat_option")
            },
            {
                labelEnglish: "Corporate Income Tax (CIT)",
                valueEnglish: "Corporate Income Tax (CIT)",
                labelArabic: t("cit_option"),
                valueArabic: t("cit_option")
            },
            {
                labelEnglish: "Withholding Tax (WHT)",
                valueEnglish: "Withholding Tax (WHT)",
                labelArabic: t("wht_option"),
                valueArabic: t("wht_option")
            },
            {
                labelEnglish: "Excise Tax",
                valueEnglish: "Excise Tax",
                labelArabic: t("excise_tax_option"),
                valueArabic: t("excise_tax_option")
            },
            {
                labelEnglish: "Zakat",
                valueEnglish: "Zakat",
                labelArabic: t("zakat_option"),
                valueArabic: t("zakat_option")
            }
        ]
    }
    else if (country === "bahrain"){
        return [
          
            {
                labelEnglish: "Value-Added Tax (VAT)",
                valueEnglish: "Value-Added Tax (VAT)",
                labelArabic: t("vat_option"),
                valueArabic: t("vat_option")
            },
            {
                labelEnglish: "Corporate Income Tax (CIT)",
                valueEnglish: "Corporate Income Tax (CIT)",
                labelArabic: t("cit_option"),
                valueArabic: t("cit_option")
            },
        ]
    }
    else if (country === "kuwait"){
        return [
            {
                labelEnglish: "Corporate Income Tax (CIT)",
                valueEnglish: "Corporate Income Tax (CIT)",
                labelArabic: t("cit_option"),
                valueArabic: t("cit_option")
            },
            {
                labelEnglish: "Withholding Tax (WHT)",
                valueEnglish: "Withholding Tax (WHT)",
                labelArabic: t("wht_option"),
                valueArabic: t("wht_option")
            },
        ]
    }

    else if(country === "oman"){
        return [
          
            {
                labelEnglish: "Value-Added Tax (VAT)",
                valueEnglish: "Value-Added Tax (VAT)",
                labelArabic: t("vat_option"),
                valueArabic: t("vat_option")
            },
            {
                labelEnglish: "Corporate Income Tax (CIT)",
                valueEnglish: "Corporate Income Tax (CIT)",
                labelArabic: t("cit_option"),
                valueArabic: t("cit_option")
            },
            {
                labelEnglish: "Withholding Tax (WHT)",
                valueEnglish: "Withholding Tax (WHT)",
                labelArabic: t("wht_option"),
                valueArabic: t("wht_option")
            },
            {
                labelEnglish: "Excise Tax",
                valueEnglish: "Excise Tax",
                labelArabic: t("excise_tax_option"),
                valueArabic: t("excise_tax_option")
            },
        ]
    }
    else if (country === "qatar"){
        return [
            {
                labelEnglish: "Corporate Income Tax (CIT)",
                valueEnglish: "Corporate Income Tax (CIT)",
                labelArabic: t("cit_option"),
                valueArabic: t("cit_option")
            },
            {
                labelEnglish: "Withholding Tax (WHT)",
                valueEnglish: "Withholding Tax (WHT)",
                labelArabic: t("wht_option"),
                valueArabic: t("wht_option")
            },
            {
                labelEnglish: "Excise Tax",
                valueEnglish: "Excise Tax",
                labelArabic: t("excise_tax_option"),
                valueArabic: t("excise_tax_option")
            },

        ]
    }

    else return [
        {
        labelEnglish: "",
        valueEnglish: "",
        labelArabic: "",
        valueArabic: ""            
        }

    ]
}