import { createSlice } from "@reduxjs/toolkit";

const GuideTour=createSlice({
    name:'guide-tour-slice',
    initialState:{
        isActive:false,
    },
    reducers:{
        changeTourState:(state,action)=>{
            state.isActive=!state.isActive
        }
    }
})

export const {changeTourState}=GuideTour.actions;
export default GuideTour.reducer